import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import GraphQLExample from '../../../components/GraphQLExample';
import * as closeSignatureOrderExample from '../../../examples/closeSignatureOrder.graphql';
export const _frontmatter = {
  "product": "signatures",
  "category": "Getting Started",
  "sort": 5,
  "title": "Document lifecycle",
  "subtitle": "Learn how Criipto Signatures handles your documents securely and safely."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Default document lifecycle`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Signature order is created:`}</strong>{` When you create a signature order with `}<inlineCode parentName="li">{`createSignatureOrder`}</inlineCode>{` you send along document blobs with it,
upon receiving it we validate it and then `}<a parentName="li" {...{
          "href": "#encryption-and-storage"
        }}>{`double-encrypt`}</a>{` it and store it on Azure servers.`}</li>
      <li parentName="ul"><strong parentName="li">{`Signatory signs:`}</strong>{` Whenever a signatory needs to sign a document we download and decrypt it to show it to the user,
once the user signs we embed the signatory evidence (usually eID claims returned by Criipto Verify) inside the PDF and seal it with Criiptos AATL certificate.
After sealing the PDF we then again `}<a parentName="li" {...{
          "href": "#encryption-and-storage"
        }}>{`double-encrypt`}</a>{` it and store it on Azure servers, ready for the next person to sign.`}</li>
      <li parentName="ul"><strong parentName="li">{`Signature order is closed:`}</strong>{` Upon closing a signature order we apply a final document timestamp to the PDF to ensure that the data inside is valid for multiple years.
You should always request document blobs as part of the close response as this is the last time they will be available. After closing the documents will be deleted from Criipto servers.`}</li>
    </ul>
    <p>{`You can download your document from the Criipto Signatures API at any point `}<strong parentName="p">{`up until after `}<inlineCode parentName="strong">{`closeSignatureOrder`}</inlineCode>{` is called`}</strong>{`.`}</p>
    <p>{`Auto-expired and cancelled signature orders also have their documents automatically deleted.`}</p>
    <h2>{`Encryption and storage`}</h2>
    <p>{`Documents are stored in Azure Datacenters in Europe, documents are always double encrypted, both by an Azure-managed key and by a non-Azure, fully EU-owned HSM.
This protects the documents from any outside access to personal data, in accordance with Schrems-II.`}</p>
    <h2>{`Extended lifecycle`}</h2>
    <p>{`If you wish to extend document lifetime so that it exists on Criiptos servers beyond `}<inlineCode parentName="p">{`closeSignatureOrder`}</inlineCode>{` you can send `}<inlineCode parentName="p">{`{retainDocumentsForDays: [number 1-7]}`}</inlineCode>{` as input to `}<inlineCode parentName="p">{`closeSignatureOrder`}</inlineCode>{`.
The documents will then stay available on the signature order until the configured amount of days has passed where at which point they will be deleted from Criiptos servers.`}</p>
    <p>{`To ensure the highest protection of personal data you `}<strong parentName="p">{`must`}</strong>{` make sure to clean up signature orders as soon as you can verify you have securely stored documents on your own servers, you can do so by calling the `}<inlineCode parentName="p">{`cleanupSignatureOrder`}</inlineCode>{` mutation.
The `}<inlineCode parentName="p">{`cleanupSignatureOrder`}</inlineCode>{` is idempotent. If you are ever in a situation where you are uncertain about whether the clean-up mutation succeed, it is better to try it again than not doing anything.`}</p>

    <GraphQLExample example={{
      query: closeSignatureOrderExample.query,
      variables: data => ({
        ...closeSignatureOrderExample.variables(data),
        retainDocumentsForDays: 1
      })
    }} mdxType="GraphQLExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      